export default {
  dev: {
    admin: 'https://mtr.skordev.com/admin-dashboard/#/report',
    app: 'https://mtr.skordev.com/',
    basePath: 'https://mtr-pwa.skordev.com/#/',
    img: 'https://mtr.skordev.com',
  },
  prod: {
    admin: 'https://cerrapoints.com/admin-dashboard/#/report',
    app: 'https://cerrapoints.com/',
    basePath: 'https://mtrmarketplace.com/#/',
    img: 'https://cerrapoints.com',
    sentryReplay: 'https://mtrmarketplace.cerrapoints.com/',
  },
};
